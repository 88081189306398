import request from "@/api/config.js"; 

export function GetMentorsList() {
  return request({
    url: `mentors/get/mentors/list`,
    method: "get",
  });
}
export function getMentorInfo(data) {
  return request({
    url: `mentors/post/mentor/info`,
    method: "post",
    data
  });
}  

export function createMentor(data) {
  return request({
    url: `mentors/post/mentors/create`,
    method: "post",
    data
  });
}
export function updateMentor(data) {
  return request({
    url: `mentors/post/mentors/update`,
    method: "post",
    data
  });
}
export function removeMentor(data) {
  return request({
    url: `mentors/post/mentors/remove`,
    method: "post",
    data
  });
}
 
export function getMentorSlotsForWeek(data) {
  return request({
    url: `mentors/post/mentor/slots/for-week`,
    method: "post",
    data
  });
}
export function createMentorSlots(data) {
  return request({
    url: `mentors/post/mentor/create-slots`,
    method: "post",
    data
  });
} 
export function removeSlots(data) {
  return request({
    url: `mentors/post/mentor/remove-slots`,
    method: "post",
    data
  });
} 
export function reserveSlotByMentor(data) {
  return request({
    url: `mentors/post/mentor/reserve-slot`,
    method: "post",
    data
  });
} 
export function cancelRecordByMentor(data) {
  return request({
    url: `mentors/post/mentor/cancel-slot-record`,
    method: "post",
    data
  });
} 
export function updateSlot(data) {
  return request({
    url: `mentors/post/mentor/update-slot`,
    method: "post",
    data
  });
} 