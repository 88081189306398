<template>
  <div class="Page-Wrapper">
    <span class="ModerateSlotsTitle">Администрирование слотов</span>
    <span class="MentorName"
      >{{ Mentor?.info?.fio }} ({{ Mentor?.info?.email }})</span
    >

    <div class="View">
      <div class="List">
        <details
          v-for="(day, dindex) in getWeekDaysWithDates"
          :key="`day-${dindex}-${day.date}-${getMonthSlots.hash}`"
          class="DetailsBlock"
        >
          <summary class="DetailsHead noselect">
            <div class="Left">
              <span class="WeekDay">{{ day.weekDay }}</span>
              <span class="WeekDay">{{
                day.date.toLocaleDateString("ru-RU", {
                  day: "numeric",
                  month: "long",
                })
              }}</span>
              <span class="WeekDay"
                >[{{
                  getReservedSlotCounter(
                    getMonthSlots.slots[day.date.toLocaleDateString("ru-RU")]
                      ?.slots,
                    day.date
                  )
                }}/{{
                  getMonthSlots.slots[day.date.toLocaleDateString("ru-RU")]
                    ?.slots?.length >= 0
                    ? getMonthSlots.slots[day.date.toLocaleDateString("ru-RU")]
                        ?.slots?.length
                    : "?"
                }}]</span
              >
            </div>

            <div class="ArrowUp"></div>
          </summary>

          <div class="DetailsList">
            <span
              v-if="
                getMonthSlots.slots[day.date.toLocaleDateString('ru-RU')]?.slots
                  ?.length === 0
              "
              class="NoSlots"
              >слотов нет</span
            >

            <span
              v-if="
                getMonthSlots.slots[day.date.toLocaleDateString('ru-RU')]?.slots
                  ?.length == null
              "
              class="NoSlots"
              >Данные за другой месяц не загружены</span
            >

            <div
              v-for="(slot, sindx) in getMonthSlots.slots[
                day.date.toLocaleDateString('ru-RU')
              ]?.slots"
              :key="`slotsList-${sindx}-${slot.id}-${slot.archieve_date}`"
              class="SlotCard"
            >
              <span v-if="slot.archieve_date !== null" class="SCIsRegular"
                >Слот является архивным</span
              >
              <span class="SDirections"
                >{{ slot.MCSDirection.map((x) => x.name).join(", ")
                }}{{
                  getUserRecord(slot, day.date) != null
                    ? ``
                    : ` • Слот свободен`
                }}</span
              >
              <div class="SCTags">
                <span
                  v-if="
                    checkIsPrevDate(this.Calendar.today, new Date(day.date))
                  "
                  class="SDateStatus"
                  >Завершено
                  {{
                    formatTimeRange(
                      slot.date,
                      slot.timezone,
                      slot.time_start,
                      slot.duration
                    )
                  }}</span
                >
                <span v-else class="SCTimeWithZone">{{
                  formatTimeRange(
                    slot.date,
                    slot.timezone,
                    slot.time_start,
                    slot.duration
                  )
                }}</span>
                <span v-if="slot.isRegular" class="SCIsRegular"
                  >Еженедельное</span
                >
              </div>

              <template v-if="getUserRecord(slot, day.date) != null">
                <div
                  class="RecordData"
                  v-for="(rec, rindx) in getUserRecord(slot, day.date)"
                  :key="`recUser-${rindx}-${rec.id}`"
                >
                  <span class="SShortInfo"
                    >{{ rec?.ConsultationRecordUser?.surname }}
                    {{ rec?.ConsultationRecordUser?.name[0] }} •
                    {{ getUniver(rec?.ConsultationRecordUser?.MapRegion) }} •
                    {{ rec?.ConsultationRecordUser?.email }}</span
                  >
                  <span class="SUserTitle">{{ rec?.title }}</span>
                  <span class="SUserComment">{{ rec?.questions }}</span>
                </div>
              </template>

              <div class="Buttons">
                <template
                  v-if="
                    !checkIsPrevDate(this.Calendar.today, new Date(day.date))
                  "
                >
                  <OUIButtonSecondary
                    v-if="getUserRecord(slot, day.date) != null"
                    ButtonText="Отменить запись"
                    @click="
                      cancelRecordSlot(getUserRecord(slot, day.date)?.[0]?.id)
                    "
                  />

                  <OUIButtonSecondary
                    v-if="
                      slot.archieve_date === null &&
                        getUserRecord(slot, day.date) == null
                    "
                    ButtonText="Зарезервировать день"
                    @click.native="reserveEmptySlot(slot.id, day.date, slot)"
                  />
                  <OUIButtonSecondary
                    v-if="slot.archieve_date === null"
                    ButtonText="Редактировать слот"
                    @click.native="openSlotModal(slot)"
                  />
                  <OUIButtonTertary
                    v-if="slot.archieve_date === null"
                    :ButtonText="
                      `Удалить ${slot.isRegular ? 'регулярный' : ''} слот`
                    "
                    @click.native="
                      () => {
                        ManageSlots.selectAll = false;
                        ManageSlots.selected = [slot.id];
                        RemoveSlotDay = day.date;
                        removeSelectedSlots();
                      }
                    "
                  />
                </template>
              </div>
            </div>
          </div>
        </details>
      </div>

      <div class="CalendarAndManagment">
        <vue-cal
          class="OUICalendar"
          hide-view-selector
          :time="false"
          active-view="month"
          :disable-views="['years', 'year', 'week', 'day', 'days']"
          locale="ru"
          xsmall
          @cell-click="calendarViewChange"
          @view-change="monthViewChange"
        >
          <template #title="{ title }">
            <div class="HorizontalAligment">
              <span>{{ title }}</span>

              <el-popover
                :width="300"
                trigger="hover"
                popper-class="oui-calendar-popover"
              >
                <template #reference>
                  <CircleQuestionIcon />
                </template>
                <template #default>
                  <div
                    class="CalendarCellsExample"
                    style="display: flex; gap: 16px; flex-direction: column"
                  >
                    <div class="CCEExample">
                      <div class="CellEmpty CellFilled">
                        <span class="CellNumber">1</span>
                      </div>

                      <span class="CellDesc" lang="ru"
                        >Нет свободных слотов</span
                      >
                    </div>

                    <div class="CCEExample">
                      <div class="CellEmpty CellHalfFilled">
                        <span class="CellNumber">1</span>
                      </div>

                      <span class="CellDesc" lang="ru"
                        >Есть не занятые слоты</span
                      >
                    </div>

                    <div class="CCEExample">
                      <div class="CellEmpty">
                        <span class="CellNumber">1</span>
                      </div>

                      <span class="CellDesc" lang="ru"
                        >Нет слотов / прошедший день</span
                      >
                    </div>

                    <div class="CCEExample">
                      <div class="CellEmpty CellToday">
                        <span class="CellNumber">1</span>
                      </div>

                      <span class="CellDesc" lang="ru">Сегодняшний день</span>
                    </div>
                  </div>
                </template>
              </el-popover>
            </div>
          </template>

          <template #cell-content="{ cell }">
            <span :class="`${getDateStyle(cell)}`">
              {{ cell.content }}
            </span>
          </template>
        </vue-cal>

        <OUIButtonPrimary
          size="medium"
          class="ButtonRestyler"
          ButtonText="Создать слот"
          @click.native="openSlotModal(null)"
        />
        <OUIButtonPrimaryOutline
          size="medium"
          class="ButtonRestyler"
          :isActive="Slots.weekView.length >= 1"
          :SendCallback="
            () => {
              ManageSlots.show = true;
            }
          "
          ButtonText="Управление слотами"
        />
      </div>
    </div>

    <NewStyledModal
      class="Styled-Modal"
      :showModal="EditSlot.show"
      :hideModal="closeSlotModal"
      :key="EditSlot.show"
    >
      <template v-slot:ModalContent>
        <div class="Offer-Modal" v-if="EditSlot.show">
          <span class="Offer-Modal-Title">
            {{
              EditSlot.id != null ? "Редактирование слота" : "Создание слота"
            }}
          </span>

          <div class="Offer-Modal-Content">
            <div class="SplitBlocks">
              <div v-if="EditSlot.id == null" class="Input-Floating-Label">
                <input
                  :class="{
                    'Input-styled': true,
                  }"
                  type="time"
                  autocomplete="off"
                  maxlength="250"
                  v-model="EditSlot.time_start"
                />
                <span class="Input-Placeholder noselect">Время начала</span>
              </div>

              <div class="Input-Floating-Label">
                <select
                  v-model="EditSlot.duration"
                  :class="{
                    'Input-styled': true,
                  }"
                >
                  <option :value="15">15 минут</option>
                  <option :value="30">30 минут</option>
                  <option :value="60">60 минут</option>
                </select>
                <span class="Input-Placeholder noselect"
                  >Продолжительность</span
                >
              </div>
            </div>

            <div v-if="EditSlot.id == null" class="Input-Floating-Label">
              <select
                v-model="EditSlot.timezone"
                :class="{
                  'Input-styled': true,
                }"
              >
                <option
                  v-for="(tz, tzindex) in Timezones"
                  :key="`timezone-${tzindex}`"
                  :value="tz.value"
                >
                  {{ tz.label }}
                </option>
              </select>
              <span class="Input-Placeholder noselect">Часовой пояс</span>
            </div>

            <div v-if="EditSlot.id == null" class="Input-Floating-Label">
              <div class="checkbox_agreement">
                <input
                  type="checkbox"
                  id="regular_repeat"
                  name="regular_repeat"
                  v-model="EditSlot.isRegular"
                  style="width: 24.4px !important"
                />
                <label for="regular_repeat">Регулярное повторение</label>
              </div>
            </div>

            <template v-if="EditSlot.id == null">
              <div v-if="EditSlot.isRegular" class="Input-Floating-Label">
                <span class="Title">Дни недели</span>
                <div class="Days noselect">
                  <div
                    v-for="(day, dindx) in Days"
                    :key="
                      `day-${dindx}-${day.day}-${EditSlot._days.includes(
                        day.day
                      )}`
                    "
                    :class="
                      `Day ${EditSlot._days.includes(day.day) ? 'active' : ''}`
                    "
                    @click="toggleWeekDay(day.day)"
                  >
                    <span>{{ day.name }}</span>
                  </div>
                </div>
              </div>
            </template>

            <template v-if="EditSlot.id == null">
              <div v-if="!EditSlot.isRegular" class="Input-Floating-Label">
                <input
                  :class="{
                    'Input-styled': true,
                  }"
                  type="date"
                  v-model="EditSlot.date"
                />
                <span class="Input-Placeholder noselect">Дата</span>
              </div>
            </template>

            <template v-if="EditSlot.id == null">
              <div v-if="!EditSlot.all_directions" class="Input-Floating-Label">
                <el-select
                  v-model="EditSlot.directions"
                  multiple
                  placeholder=""
                  class="OUIElementMultiSelect"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(sect, sindx) in Mentor.info.MentorDirection"
                    :key="`sect-${sindx}-${sect.id}`"
                    :value="sect.id"
                    :label="sect.name"
                  />
                </el-select>

                <span class="Input-Placeholder noselect"
                  >Выберите направления</span
                >
              </div>

              <div class="Input-Floating-Label">
                <div class="checkbox_agreement">
                  <input
                    type="checkbox"
                    id="all_directions"
                    name="all_directions"
                    v-model="EditSlot.all_directions"
                    style="width: 24.4px !important"
                  />
                  <label for="all_directions">Все направления</label>
                </div>
              </div>
            </template>

            <div class="Input-Floating-Label">
              <select
                v-model="EditSlot.restricted_before_hours"
                :class="{
                  'Input-styled': true,
                }"
              >
                <option :value="1">1 час до консультации</option>
                <option :value="2">2 часа до консультации</option>
                <option :value="3">3 часа до консультации</option>
                <option :value="6">6 часов до консультации</option>
                <option :value="12">12 часов до консультации</option>
                <option :value="18">18 часов до консультации</option>
                <option :value="24">24 часа до консультации</option>
                <option :value="48">48 часов до консультации</option>
              </select>
              <span class="Input-Placeholder noselect"
                >Запретить запись за:</span
              >
            </div>
          </div>

          <div class="Offer-Modal-Buttons noselect">
            <span
              class="Offer-Button-Next"
              @click="createOrEdit"
              style="width: 100%"
              >{{
                EditSlot.id != null ? "Cохранить изменения" : "Создать слот"
              }}</span
            >
            <span class="Offer-Button-Prev" @click="closeSlotModal"
              >Отмена</span
            >
          </div>
        </div>
      </template>
    </NewStyledModal>

    <NewStyledModal
      class="Styled-Modal"
      :showModal="ManageSlots.show"
      :hideModal="
        () => {
          ManageSlots.show = false;
          ManageSlots.selected = [];
        }
      "
      :key="ManageSlots.show"
    >
      <template v-slot:ModalContent>
        <div class="Offer-Modal" v-if="ManageSlots.show">
          <span class="Offer-Modal-Title"> Управление слотами </span>

          <div class="Offer-Modal-Content">
            <div class="Input-Floating-Label">
              <div class="checkbox_agreement">
                <input
                  type="checkbox"
                  id="choose_all_slots"
                  name="choose_all_slots"
                  v-model="ManageSlots.selectAll"
                  style="width: 24.4px !important"
                />
                <label for="choose_all_slots">Выбрать все слоты</label>
              </div>
            </div>

            <div class="VirtualList" :key="`slots-hash-${slotsWeekViewHash}`">
              <div
                v-for="(rslot, rsindx) in Slots.weekView"
                :key="`RemoveSlot-${rsindx}-${rslot.id}`"
                class="Element"
              >
                <template v-if="ManageSlots.selectAll">
                  <input
                    type="checkbox"
                    style="width: 24.4px !important"
                    :checked="true"
                  />
                </template>
                <template v-else>
                  <input
                    type="checkbox"
                    style="width: 24.4px !important"
                    :value="rslot.id"
                    :checked="ManageSlots.selectAll"
                    v-model="ManageSlots.selected"
                  />
                </template>

                <div class="ElementBubble">
                  <div class="SCTags">
                    <span class="SCTimeWithZone">{{
                      getFormattedTime(
                        rslot.date,
                        rslot.timezone,
                        rslot.time_start,
                        rslot.duration
                      )
                    }}</span>
                    <template v-if="rslot.date == null">
                      <span class="SCIsRegular">Еженедельное</span>
                      <span class="SCDateText">{{
                        Days.find((x) => x.day === rslot.week_day)?.name
                      }}</span>
                    </template>

                    <template v-else>
                      <span class="SCDateText">{{
                        new Date(rslot.date).toLocaleDateString("ru-RU", {
                          day: "numeric",
                          month: "long",
                        })
                      }}</span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="Offer-Modal-Buttons noselect">
            <span
              class="Offer-Button-Next"
              @click="removeSelectedSlots"
              style="width: 100%"
              >Удалить выбранное</span
            >
            <span
              class="Offer-Button-Prev"
              @click="
                () => {
                  ManageSlots.show = false;
                  ManageSlots.selected = [];
                }
              "
              >Отмена</span
            >
          </div>
        </div>
      </template>
    </NewStyledModal>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";

import OUIButtonPrimary from "@/components/OctaUI/Buttons/primary.vue";
import OUIButtonPrimaryOutline from "@/components/OctaUI/Buttons/primary_outline.vue";
import OUIButtonSecondary from "@/components/OctaUI/Buttons/secondary.vue";
import OUIButtonTertary from "@/components/OctaUI/Buttons/tertary.vue";
import CircleQuestionIcon from "@/components/CustomElements/CircleQuestionIcon.vue";
import NewStyledModal from "@/components/CustomElements/NewStyledModal.vue";

import {
  getMentorInfo,
  createMentorSlots,
  getMentorSlotsForWeek,
  removeSlots,
  reserveSlotByMentor,
  cancelRecordByMentor,
  updateSlot,
} from "@/api/mentors.js";

import { ElMessage, ElMessageBox } from "element-plus";

export default {
  data() {
    return {
      Mentor: {
        id: null,
        info: null,
      },

      Slots: {
        weekView: [],
      },

      Calendar: {
        viewTimezone: "Asia/Irkutsk",
        today: new Date(),
      },

      ViewDay: new Date(),
      RemoveSlotDay: null,

      EditSlot: {
        show: false,
        id: null,
        time_start: "12:00",
        date: null,
        duration: 15,
        timezone: "Asia/Irkutsk",
        all_directions: true,
        restricted_before_hours: 3,
        // week_day
        isRegular: false,
        directions: [],
        _days: [],
      },

      ManageSlots: {
        show: false,

        selectAll: false,
        selected: [],
      },

      Days: [
        {
          day: 0,
          name: "Пн",
        },
        {
          day: 1,
          name: "Вт",
        },
        {
          day: 2,
          name: "Ср",
        },
        {
          day: 3,
          name: "Чт",
        },
        {
          day: 4,
          name: "Пт",
        },
        {
          day: 5,
          name: "Сб",
        },
        {
          day: 6,
          name: "Вс",
        },
      ],

      Timezones: [
        { value: "Europe/Belfast", label: "(UTC +00:00) Среднее  по Гринвичу" },
        { value: "Europe/Lisbon", label: "(UTC +00:00) Западная Европа" },
        { value: "Europe/Amsterdam", label: "(UTC +01:00) Центральная Европа" },
        { value: "Asia/Beirut", label: "(UTC +02:00) Восточная Европа" },
        { value: "Asia/Jerusalem", label: "(UTC +02:00) Израиль" },
        { value: "Europe/Kaliningrad", label: "(UTC +02:00) Калининград" },
        { value: "Asia/Aden", label: "(UTC +03:00) Саудовская Аравия" },
        { value: "Europe/Minsk", label: "(UTC +03:00) Москва" },
        { value: "Europe/Volgograd", label: "(UTC +03:00) Волгоград" },
        { value: "Asia/Tehran", label: "(UTC +03:30) Иран" },
        { value: "Asia/Baku", label: "(UTC +04:00) Азербайджан" },
        { value: "Asia/Dubai", label: "(UTC +04:00) Персидский залив" },
        { value: "Asia/Tbilisi", label: "(UTC +04:00) Грузия" },
        { value: "Asia/Yerevan", label: "(UTC +04:00) Армения" },
        { value: "Europe/Samara", label: "(UTC +04:00) Самара" },
        { value: "Europe/Saratov", label: "(UTC +04:00) Саратов" },
        { value: "Europe/Ulyanovsk", label: "(UTC +04:00) Ульяновск" },
        { value: "Asia/Kabul", label: "(UTC +04:30) Афганистан" },
        { value: "Asia/Ashgabat", label: "(UTC +05:00) Туркменистан" },
        { value: "Asia/Dushanbe", label: "(UTC +05:00) Таджикистан" },
        { value: "Asia/Karachi", label: "(UTC +05:00) Пакистан" },
        { value: "Asia/Samarkand", label: "(UTC +05:00) Узбекистан" },
        { value: "Asia/Yekaterinburg", label: "(UTC +05:00) Екатеринбург" },
        { value: "Asia/Calcutta", label: "(UTC +05:30) Индия" },
        { value: "Asia/Kathmandu", label: "(UTC +05:45) Непал" },
        { value: "Asia/Bishkek", label: "(UTC +06:00) Киргизия" },
        { value: "Asia/Dacca", label: "(UTC +06:00) Бангладеш" },
        { value: "Asia/Omsk", label: "(UTC +06:00) Омск" },
        { value: "Asia/Thimbu", label: "(UTC +06:00) Бутан" },
        { value: "Asia/Rangoon", label: "(UTC +06:30) Мьянма" },
        { value: "Asia/Bangkok", label: "(UTC +07:00) Индокитай" },
        { value: "Asia/Barnaul", label: "(UTC +07:00) Барнаул" },
        { value: "Asia/Hovd", label: "(UTC +07:00) Ховд" },
        { value: "Asia/Jakarta", label: "(UTC +07:00) Западная Индонезия" },
        { value: "Asia/Krasnoyarsk", label: "(UTC +07:00) Красноярск" },
        { value: "Asia/Novosibirsk", label: "(UTC +07:00) Новосибирск" },
        { value: "Asia/Tomsk", label: "(UTC +07:00) Томск" },
        { value: "Asia/Brunei", label: "(UTC +08:00) Бруней-Даруссалам" },
        { value: "Asia/Choibalsan", label: "(UTC +08:00) Улан-Батор" },
        { value: "Asia/Chongqing", label: "(UTC +08:00) Китай" },
        { value: "Asia/Hong_Kong", label: "(UTC +08:00) Гонконг" },
        { value: "Asia/Irkutsk", label: "(UTC +08:00) Иркутск" },
        { value: "Asia/Kuala_Lumpur", label: "(UTC +08:00) Малайзия" },
        { value: "Asia/Makassar", label: "(UTC +08:00) Центральная Индонезия" },
        { value: "Asia/Manila", label: "(UTC +08:00) Филиппины" },
        { value: "Asia/Singapore", label: "(UTC +08:00) Сингапур" },
        { value: "Asia/Taipei", label: "(UTC +08:00) Тайвань" },
        { value: "Asia/Chita", label: "(UTC +09:00) Чита" },
        { value: "Asia/Dili", label: "(UTC +09:00) Восточный Тимор" },
        { value: "Asia/Jayapura", label: "(UTC +09:00) Восточная Индонезия" },
        { value: "Asia/Khandyga", label: "(UTC +09:00) Якутск" },
        { value: "Asia/Pyongyang", label: "(UTC +09:00) Корея" },
        { value: "Asia/Tokyo", label: "(UTC +09:00) Япония" },
        { value: "Asia/Ust-Nera", label: "(UTC +10:00) Владивосток" },
        { value: "Asia/Magadan", label: "(UTC +11:00) Магадан" },
        { value: "Asia/Sakhalin", label: "(UTC +11:00) Сахалин" },
        { value: "Asia/Anadyr", label: "(UTC +12:00) Анадырь" },
        {
          value: "Asia/Kamchatka",
          label: "(UTC +12:00) Петропавловск-Камчатский",
        },
      ],
    };
  },
  components: {
    OUIButtonPrimary,
    OUIButtonPrimaryOutline,
    VueCal,
    CircleQuestionIcon,
    NewStyledModal,
    OUIButtonSecondary,
    OUIButtonTertary,
  },
  async created() {
    if (this.$route.query?.id != null) {
      this.Mentor.id = this.$route.query?.id;
      try {
        let mentorResp = await getMentorInfo({
          id: this.Mentor.id,
          type: "",
        });
        this.Mentor.info = mentorResp.data;
      } catch (e) {
        alert("Произошла ошибка на стороне сервера при получении ментора");
        this.$router.push("/admin/mentors");
      }
    } else {
      this.$router.push("/admin/mentors");
    }

    await this.initialization();
  },
  computed: {
    getWeekDaysWithDates() {
      const daysOfWeek = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
      const currentDayIndex =
        this.ViewDay.getDay() === 0 ? 6 : this.ViewDay.getDay() - 1; // Приводим воскресенье (0) к 6

      return Array.from({ length: 7 }, (_, i) => {
        let newDate = new Date(this.ViewDay);
        newDate.setDate(this.ViewDay.getDate() - currentDayIndex + i); // Сдвигаем дату

        return {
          weekDay:
            daysOfWeek[newDate.getDay() === 0 ? 6 : newDate.getDay() - 1],
          date: newDate,
        };
      });
    },

    getMonthSlots() {
      const viewDay = this.ViewDay;
      if (viewDay == null || viewDay === "") {
        return {}; // или можно вернуть пустой объект
      }

      const slotsResult = {
        viewDay: viewDay,
        hash: "#",
      };
      const slotsByDate = {};
      let calendarDays = [];

      const { startDate, endDate } = this.getMonthStartAndEnd(viewDay);

      let currentDate = startDate;
      while (currentDate <= endDate) {
        const formattedDate = currentDate.toLocaleDateString("ru-RU"); // Приводим дату к "ДД.ММ.ГГГГ"
        slotsByDate[formattedDate] = { slots: [] };
        calendarDays.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1); // Переходим к следующему дню
      }

      this.Slots.weekView.forEach((_slot) => {
        let slot = JSON.parse(JSON.stringify(_slot));
        const slotDate = slot.date;

        if (slotDate != null) {
          slot.isRegular = false;
          // Преобразуем slotDate в целевой часовой пояс
          const slotLocalDate = new Date(
            new Date(slotDate).toLocaleString("en-US", {
              timeZone: this.Calendar.viewTimezone,
            })
          );

          const formattedSlotDate = slotLocalDate.toLocaleDateString("ru-RU");

          if (slotsByDate[formattedSlotDate]) {
            slotsByDate[formattedSlotDate].slots.push(slot);
          }
        } else {
          slot.isRegular = true;

          calendarDays.forEach((date) => {
            const localDate = new Date(
              new Date(date).toLocaleString("en-US", {
                timeZone: this.Calendar.viewTimezone,
              })
            );

            const localWeekDay = (localDate.getDay() + 6) % 7; // Приводим к 0 - Пн, 6 - Вс

            if (localWeekDay === slot.week_day) {
              slot.date = localDate; // Устанавливаем дату слота
              const formattedDate = localDate.toLocaleDateString("ru-RU");

              if (slot.archieve_date === null) {
                slotsByDate[formattedDate].slots.push(slot);
              } else {
                if (
                  this.checkIsPrevDate(new Date(slot.archieve_date), localDate)
                ) {
                  slotsByDate[formattedDate].slots.push(slot);
                }
              }
            }
          });
        }
      });

      return {
        ...slotsResult,
        hash: `#${this.getWeekDaysWithDates
          .map((day) =>
            day.date.toLocaleDateString("ru-RU", {
              day: "numeric",
              month: "long",
            })
          )
          .join("-")}`,
        slots: slotsByDate,
      };
    },

    slotsWeekViewHash() {
      let a = this.Slots.weekView.map((x, xindx) => `${xindx}-${x.id}`);
      return `${this.Slots.weekView?.length}-${a.join("-")}`;
    },
  },
  methods: {
    getUniver(MapUniver) {
      if (MapUniver == null) {
        return "ВУЗ не указан";
      }

      if (MapUniver.UsersChoosedUniver != null) {
        if (MapUniver.UsersChoosedUniver.name == "Другое") {
          return MapUniver.univer_other;
        }
        return MapUniver.UsersChoosedUniver.name;
      }

      if (MapUniver.univer_other == null || MapUniver.univer_other == "") {
        return "ВУЗ не указан";
      } else {
        return MapUniver.univer_other;
      }
    },

    getReservedSlotCounter(slots, day) {
      if (slots == null || !Array.isArray(slots) || slots.length === 0) {
        return 0;
      }

      let res = 0;
      for (let slot of slots) {
        let userRec = this.getUserRecord(slot, day);
        if (userRec != null) {
          res++;
        }
      }

      return res;
    },

    async initialization() {
      try {
        const { startDate, endDate } = this.getMonthStartAndEnd(this.ViewDay);

        let mentorSlotsForWeekResp = await getMentorSlotsForWeek({
          MentorID: this.Mentor.id,
          date_start: startDate,
          date_end: endDate,
          timeZone: "Asia/Irkutsk",
        });
        this.Slots.weekView = mentorSlotsForWeekResp.data;
      } catch (e) {
        alert("Произошла ошибка при получении слотов");
      }
    },

    getDateStyle(cell) {
      let formattedDate = this.formatDate(cell?.formattedDate);

      if (
        this.checkIsPrevDate(this.Calendar.today, new Date(cell?.formattedDate))
      ) {
        return `oui_calendar_cell`;
      }

      /*

      oui_calendar_full_slots

      [{{
                  getReservedSlotCounter(
                    getMonthSlots.slots[day.date.toLocaleDateString("ru-RU")]
                      ?.slots,
                    day.date
                  )
                }}/{{
                  getMonthSlots.slots[day.date.toLocaleDateString("ru-RU")]
                    ?.slots?.length
                }}]

      */

      if (
        this.getReservedSlotCounter(
          this.getMonthSlots.slots[formattedDate]?.slots,
          new Date(cell?.formattedDate)
        ) === this.getMonthSlots.slots[formattedDate]?.slots?.length &&
        this.getMonthSlots.slots[formattedDate]?.slots?.length != 0
      ) {
        return `oui_calendar_cell  oui_calendar_full_slots`;
      }

      let hasSlots =
        this.getMonthSlots?.slots?.[formattedDate]?.slots?.length >= 1;

      return `oui_calendar_cell ${
        hasSlots ? "oui_calendar_cell_has_slots" : ""
      }`;
    },

    openSlotModal(slot) {
      if (slot != null) {
        this.EditSlot.id = slot.id;
        this.EditSlot.restricted_before_hours = slot.restricted_before_hours;
        this.EditSlot.duration = slot.duration;
      }
      this.EditSlot.date = new Date().toISOString().split("T")[0];
      this.EditSlot.isRegular = true;
      this.EditSlot.directions = this.Mentor.info.MentorDirection.map(
        (x) => x.id
      );

      this.EditSlot.show = true;
    },
    closeSlotModal() {
      this.EditSlot = {
        show: false,

        id: null,
        time_start: "12:00",
        date: null,
        duration: 15,
        timezone: "Asia/Irkutsk",
        all_directions: true,
        restricted_before_hours: 3,
        // week_day
        isRegular: false,
        directions: [],
        _days: [],
      };
    },
    async createOrEdit() {
      if (this.EditSlot.id == null) {
        let slots = [];

        if (this.EditSlot.isRegular) {
          for (let day of this.EditSlot._days) {
            let slotObj = {
              time_start: this.EditSlot.time_start,
              duration: this.EditSlot.duration,
              all_directions: this.EditSlot.all_directions,
              directions: this.EditSlot.all_directions
                ? this.Mentor.info.MentorDirection.map((x) => x.id)
                : this.EditSlot.directions,
              week_day: day,
              restricted_before_hours: this.EditSlot.restricted_before_hours,
              date: null,
              timezone: this.EditSlot.timezone,
              mentor_cs_id: this.Mentor.id,
            };
            slots.push(slotObj);
          }
        } else {
          let slotObj = {
            time_start: this.EditSlot.time_start,
            duration: this.EditSlot.duration,
            all_directions: this.EditSlot.all_directions,
            directions: this.EditSlot.all_directions
              ? this.Mentor.info.MentorDirection.map((x) => x.id)
              : this.EditSlot.directions,
            week_day: this.getWeekdayFromDate(
              this.getDateWithTime(
                this.EditSlot.date,
                this.EditSlot.time_start,
                this.EditSlot.timezone
              ),
              this.EditSlot.timezone
            ),
            restricted_before_hours: this.EditSlot.restricted_before_hours,
            date: this.getDateWithTime(
              this.EditSlot.date,
              this.EditSlot.time_start,
              this.EditSlot.timezone
            ),
            timezone: this.EditSlot.timezone,
            mentor_cs_id: this.Mentor.id,
          };
          slots.push(slotObj);
        }

        try {
          await createMentorSlots({
            slots: slots,
          });

          ElMessage({
            message: "Слот(-ы) успешно созданы!",
            type: "success",
          });
        } catch (e) {
          ElMessage.error("Произошла ошибка на стороне сервера");
          return 0;
        }
      } else {
        try {
          await updateSlot({
            id: this.EditSlot.id,
            restricted_before_hours: this.EditSlot.restricted_before_hours,
            duration: this.EditSlot.duration,
          });
          ElMessage({
            message: "Слот успешно изменен!",
            type: "success",
          });
        } catch (e) {
          ElMessage.error("Произошла ошибка на стороне сервера");
          return 0;
        }
      }

      this.closeSlotModal();
      await this.initialization();
    },
    async removeSelectedSlots() {
      try {
        let res = await removeSlots({
          ids: this.ManageSlots.selectAll
            ? this.Slots.weekView.map((x) => x.id)
            : this.ManageSlots.selected,
          date: this.RemoveSlotDay,
        });

        if (parseInt(res.data) === 0) {
          ElMessage({
            message: "Слот(-ы) успешно удалены!",
            type: "success",
          });
        } else {
          ElMessage({
            message: `${res.data} слот(-ов) не удалось удалить`,
            type: "warning",
          });
        }

        this.ManageSlots.show = false;
      } catch (e) {
        ElMessage.error("Произошла ошибка на стороне сервера");
      }

      this.RemoveSlotDay = null;
      await this.initialization();
    },
    async reserveEmptySlot(slotID, date, fullSlot) {
      await this.initialization();

      if (this.getUserRecord(fullSlot, date) != null) {
        ElMessage.error("Слот занят");
        return 0;
      }

      const dateObj = new Date(date);
      const formattedDate = dateObj.toLocaleDateString("ru-RU");

      try {
        await reserveSlotByMentor({
          slotID: slotID,
          date: formattedDate,
        });

        ElMessage({
          message: "Слот успешно зарезервирован!",
          type: "success",
        });
      } catch (e) {
        console.log(e);
        ElMessage.error("Произошла ошибка на стороне сервера");
        return 0;
      }

      await this.initialization();
    },
    async cancelRecordSlot(recID) {
      try {
        await cancelRecordByMentor({
          recID: recID,
        });

        ElMessage({
          message: "Запись успешно отменена!",
          type: "success",
        });
      } catch (e) {
        console.log(e);
        ElMessage.error("Произошла ошибка на стороне сервера");
        return 0;
      }

      await this.initialization();
    },

    getNextWeekday(targetDay, time, timeZone) {
      const now = new Date();
      const today = now.getDay(); // День недели (0 - воскресенье, 1 - понедельник, ...)
      const currentDay = (today + 6) % 7; // Приводим к формату (0 - понедельник, ..., 6 - воскресенье)

      let daysToAdd = (targetDay - currentDay + 7) % 7;
      if (daysToAdd === 0) daysToAdd = 7; // Если сегодня целевой день, берем его на следующей неделе

      const [hours, minutes] = time.split(":").map(Number);

      const resultDate = new Date(now);
      resultDate.setDate(now.getDate() + daysToAdd);
      resultDate.setHours(hours, minutes, 0, 0);

      return new Date(resultDate.toLocaleString("en-US", { timeZone }));
    },
    getDateWithTime(dateString, time, timeZone) {
      const [year, month, day] = dateString.split("-").map(Number);
      const [hours, minutes] = time.split(":").map(Number);

      const resultDate = new Date(year, month - 1, day, hours, minutes, 0, 0);

      return new Date(resultDate.toLocaleString("en-US", { timeZone }));
    },
    getWeekdayFromDate(date, timeZone) {
      const localizedDate = new Date(
        date.toLocaleString("en-US", { timeZone })
      );
      return (localizedDate.getDay() + 6) % 7; // Приводим к формату (0 - Пн, 6 - Вс)
    },
    formatDate(_date) {
      let date = new Date(_date);

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      return `${day}.${month}.${year}`;
    },
    formatTimeRange(date, timeZone, timeStart, duration) {
      const [hours, minutes] = timeStart.split(":").map(Number);
      const startDate = new Date(date);
      startDate.setHours(hours, minutes, 0, 0);

      const endDate = new Date(startDate);
      endDate.setMinutes(endDate.getMinutes() + duration);

      const options = {
        timeZone,
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      };
      const startTime = startDate.toLocaleTimeString("ru-RU", options);
      const endTime = endDate.toLocaleTimeString("ru-RU", options);

      let findTimezone = this.Timezones.find((x) => x.value === timeZone);
      const city = findTimezone.label.split(") ")[1]; // Получаем город из таймзоны

      return `${startTime} – ${endTime} — ${city}`;
    },
    getFormattedTime(date, timeZone, timeStart, duration) {
      const [hours, minutes] = timeStart.split(":").map(Number);

      if (date == null) {
        date = new Date();
      }

      const startDate = new Date(date);
      startDate.setHours(hours, minutes, 0, 0);

      const endDate = new Date(startDate);
      endDate.setMinutes(endDate.getMinutes() + duration);

      const options = {
        timeZone,
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      };
      const startTime = startDate.toLocaleTimeString("ru-RU", options);
      const endTime = endDate.toLocaleTimeString("ru-RU", options);

      return `${startTime} – ${endTime}`;
    },

    toggleWeekDay(day) {
      if (this.EditSlot._days.includes(day)) {
        let index = this.EditSlot._days.findIndex((x) => x === day);
        if (index != null) {
          this.EditSlot._days.splice(index, 1);
        }
      } else {
        this.EditSlot._days.push(day);
      }
    },

    checkMonthOrYearDifference(date1, date2) {
      return (
        date1.getFullYear() !== date2.getFullYear() ||
        date1.getMonth() !== date2.getMonth()
      );
    },
    checkIsPrevDate(today, date2) {
      // Преобразуем строки в объекты Date
      const d1 = new Date(today);
      const d2 = new Date(date2);

      // Проверяем, является ли дата1 больше даты2
      return d1 > d2;
    },

    async calendarViewChange(_newDate) {
      let currentDate = this.ViewDay;
      let newDate = new Date(_newDate);
      let MonthOrYearChanged = this.checkMonthOrYearDifference(
        currentDate,
        newDate
      );

      this.ViewDay = newDate;
      if (MonthOrYearChanged) {
        await this.initialization();
      }
    },
    async monthViewChange(_newDate) {
      await this.calendarViewChange(_newDate.startDate);
    },
    getMonthStartAndEnd(date) {
      const startDate = new Date(date.getFullYear(), date.getMonth(), 1); // Начало месяца (1-е число)
      const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0); // Конец месяца (последний день месяца)

      return { startDate, endDate };
    },

    getUserRecord(slot, date) {
      let hasRecords = slot?.SlotConsultationRecord;

      if (hasRecords == null || hasRecords.length === 0) {
        return null;
      }

      const [hours, minutes] = slot.time_start.split(":").map(Number);
      const slotDate = new Date(date);
      slotDate.setHours(hours, minutes, 0, 0);

      const slotDateOptions = {
        timeZone: slot.timezone,
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      };
      const slotDateTime = slotDate.toLocaleTimeString(
        "ru-RU",
        slotDateOptions
      );

      for (let rec of hasRecords) {
        const [_day, _time] = rec.day_with_time_start.split("T");
        const [_hours, _minutes, _others] = _time.split(":").map(Number);

        const userRecordDate = new Date(_day);
        userRecordDate.setHours(_hours, _minutes, 0, 0);

        const recDateOptions = {
          timeZone: rec.timezone,
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        };

        const recDateTime = userRecordDate.toLocaleTimeString(
          "ru-RU",
          recDateOptions
        );

        if (
          slotDate.getTime() === userRecordDate.getTime() &&
          slotDateTime === recDateTime
        ) {
          return [rec];
        }
      }

      return null;
    },
  },
};
</script>

<style scoped>
.Buttons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}

.SCDateText {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-weight: 400;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: left;

  color: #ffffffe5;
}

.VirtualList {
  position: relative;
  display: flex;

  padding-right: 12px;

  width: 100%;
  height: 240px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  overflow-y: auto;
}
.Element {
  position: relative;
  display: flex;

  width: auto;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
}
.Element > * {
  margin: auto 0% !important;
}
.ElementBubble {
  position: relative;
  display: flex;

  padding: 20px 30px;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;

  background-color: #27235c;
  border-radius: var(--o-s-global-border-radius);
}

.NoSlots {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 2%;
  text-align: center;
  text-transform: uppercase;

  color: #d3caff4d;
}
.SCTags {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}
.SCTimeWithZone {
  position: relative;
  display: block;

  padding: 4px 8px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 0%;
  color: #ffffffe5;

  background-color: #656bff33;

  border-radius: 4px;
}
.SCIsRegular {
  position: relative;
  display: block;

  padding: 4px 8px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 0%;

  color: #f9dc5c;

  background-color: #f9dc5c26;

  border-radius: 4px;
}

.Page-Wrapper {
  position: relative;
  display: flex;

  margin: 0% auto;
  padding-top: 100px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 36px;

  width: 100%;
  max-width: 1200px;
  min-height: 100svh;

  padding-right: 15px;

  overflow: auto;
}

.ModerateSlotsTitle {
  position: relative;
  display: block;

  margin-bottom: 4px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #ffffff;
}
.MentorName {
  position: relative;
  display: block;

  margin-bottom: 4px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0%;
  color: #ffffff;
}

.View {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
}

.List {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 1;
  gap: 24px;
}
.CalendarAndManagment {
  position: relative;
  display: flex;

  width: 342px;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  gap: 24px;
}

.ButtonRestyler {
  width: 100%;
}
.ButtonRestyler:deep(.TText) {
  margin: 0% auto;
}

.DetailsBlock {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;
}
.DetailsHead {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  list-style: none;
}
.DetailsHead > * {
  margin: auto 0%;
}
.DetailsBlock[open] .ArrowUp {
  rotate: 0deg;
}

.ArrowUp {
  position: relative;
  display: block;

  width: 15px;
  height: 15px;

  background: url("./../../../assets/img/ReDesign/interface_icons/arrow_up.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  rotate: 180deg;
  transition: 0.2s;
}

.Left {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.WeekDay {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  line-height: 17.07px;
  letter-spacing: 0%;

  color: #ffffff;
}

.DetailsList {
  position: relative;
  display: flex;

  margin-top: 24px;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
}

.SlotCard {
  position: relative;
  display: flex;

  padding: 20px;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  background-color: #1e1b4a;
  border-radius: var(--o-s-global-border-radius);
}
.SDirections {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 2%;

  color: #d3caff4d;
}
.SDateStatus {
  position: relative;
  display: block;

  padding: 4px 8px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-weight: 500;
  font-size: 13px;
  line-height: 15.85px;
  letter-spacing: 0%;

  color: #ffffffe5;
  background-color: #656bff33;

  border-radius: 4px;
}
.RecordData {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}
.SShortInfo {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-weight: 400;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0%;

  color: #ffffffe5;
}
.SUserTitle {
  position: relative;
  display: block;

  min-width: 0;
  width: fit-content;
  max-width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 2%;
  text-align: left;
  color: #d3caffb2;
  text-transform: uppercase;
}
.SUserComment {
  position: relative;
  display: block;

  min-width: 0;
  width: fit-content;
  max-width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-weight: 400;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: left;
  color: #d3caffb2;
}
.MoreInfoButton {
  position: relative;
  display: block;

  padding-bottom: 2px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-weight: 400;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0%;
  color: #e8e7ec;

  border-bottom: 1px solid #e8e7ec;
}
.HorizontalAligment {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 12px;
}
.HorizontalAligment > * {
  margin: auto 0%;
}
.CalendarCellsExample {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
}
.CCEExample {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}
.CCEExample > * {
  margin: auto 0%;
}
.CellEmpty {
  position: relative;
  display: flex;

  width: 36px;
  height: 36px;

  flex-shrink: 0;

  background-color: #27235c;
  border-radius: var(--o-s-global-border-radius);
  border: 1px solid #27235c;
}
.CellEmpty.CellHalfFilled {
  border: 1px solid #a826d6;
}
.CellEmpty.CellFilled {
  background-color: #a826d6;
  border: 1px solid #a826d6;
}
.CellEmpty.CellToday {
  background-color: #574dd6;
}
.CellNumber {
  position: relative;
  display: block;

  margin: auto;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0%;

  color: white;
}
.CellDesc {
  position: relative;
  display: block;

  min-width: 0;
  width: fit-content;
  max-width: 100%;

  height: fit-content;

  font-family: "Montserrat";
  font-weight: 400;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0%;
  text-align: left;

  hyphens: auto;
  word-break: keep-all;
  word-wrap: normal;

  color: #ffffffe5;
}
</style>

<style scoped>
.Days {
  position: relative;
  display: flex;

  margin-top: 8px;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
}
.Day {
  position: relative;
  display: flex;

  padding: 8px 0px;

  width: 100%;
  height: fit-content;

  flex-shrink: 1;

  border-radius: 4px;

  background-color: #656bff1a;
  color: #656bff;
}
.Day.active {
  background-color: #656bff40;
  color: #ffffffe5;
}
.Day > span {
  position: relative;
  display: block;

  margin: auto;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0%;
}

.Title {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1%;
  text-align: left;

  color: #d3caff4d;
}

.checkbox_agreement {
  color: #fff;
  font-family: "Montserrat";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0.3rem;
  align-items: center;
}

.checkbox_agreement a {
  font-weight: 500;
  color: #6c59f7;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid #6e6a9a;
  border-radius: 4px;
  padding: 0.7rem;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;

  margin-right: 1rem;
}

input[type="checkbox"]:checked {
  background-image: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #fff;

  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

select.Input-styled::-ms-expand {
  display: none;
}
select.Input-styled option {
  background-color: white;
  color: black;
}

.SplitBlocks {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
}

.SplitBlocks > * {
  width: 50%;
  flex-shrink: 1;
}

.Styled-Modal:deep(.SendRequestModal) {
  min-width: 680px;
  width: fit-content;
  background: #1e1b4a;
}
.Offer-Modal {
  position: relative;
  display: flex;

  padding-top: 12px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: auto;
  height: fit-content;
}

.Offer-Modal-Title {
  position: relative;
  display: block;

  min-width: 320px;
  width: auto;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;
}
.Offer-Modal-Content {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  min-width: 320px;
  width: auto;
  height: fit-content;
}

.Input-Floating-Label {
  position: relative;
  display: block;

  min-width: 320px;
  width: auto;
  height: fit-content;
}

.Input-styled {
  position: relative;
  display: block;

  padding: 12px 8px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  background-color: transparent;

  outline: 0;
  outline-offset: 0;

  border-radius: 4px;
  border: 1px solid #d3caff40;
}
textarea.Input-styled {
  min-height: 120px;
}

.Input-styled::placeholder {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #d3caff73;
}
.Input-styled:focus {
  border-color: #656bff;
}
.Input-styled.Input-highligt-error {
  border-color: #ed254e;
}
.Input-styled.Input-highligt-error:focus + .Input-Placeholder,
.Input-styled.Input-highligt-error + .Input-Placeholder {
  color: #ed254e;
}

.Input-Placeholder {
  position: absolute;
  display: block;

  top: 0;
  left: 18px;

  width: auto;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, -50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Placeholder {
  color: #656bff;
}

.Input-Text-Length {
  position: absolute;
  display: block;

  bottom: 0;
  right: 18px;

  width: auto;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, 50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Text-Length {
  color: #656bff;
}

.Offer-Modal-Buttons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
  height: fit-content;
}

.Offer-Button-Next {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: 100%;
  height: fit-content;

  flex-shrink: 1;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff;
}
.Offer-Button-Prev {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  flex-shrink: 0;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff40;
}
.Offer-Button-Prev:hover {
  background-color: rgba(101, 106, 255, 0.144);
}

.Offer-Text-Before-Confirm {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #d3caffb2;
}
.Offer-Text-Before-Confirm .Text-Before-Confirm-Important {
  color: #ffffffe5;
}

.Offer-Modal-Buttons-Child-Wrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 320px;
  height: fit-content;
}

.Offer-Text-Balance-After {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;

  color: #d3caff4d;
}
.Offer-Text-Error {
  position: relative;
  display: block;

  margin-top: 12px;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: none;
  color: #ed254e;
}
</style>
